import React from "react";
import { graphql } from "gatsby"


class Post extends React.Component {
  render() {
    return (
      <div className="postHookSv"></div>
    )
  }
}

export default Post

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`